<!-- eslint-disable vue/no-v-html -->
<template>
	<div class="row mb-2">
		<div
			v-if="!!Object.keys(address).length && !loadingOverride"
			class="col-auto">
			<p class="mb-0">
				<b>{{ address.first_name }} {{ address.last_name }}</b>
			</p>
			<p class="mb-0">
				{{ address.address }}
			</p>
			<p class="mb-0">
				{{ address.address2 }}
			</p>
			<p class="mb-0">
				{{ address.city }}, {{ address.region.name }}, {{ address.postcode }}
			</p>
			<p class="mb-0">
				{{ translate(address.country.code.toLowerCase()) }}
			</p>
			<div
				v-if="Object.keys(shippingMethodInfo).length && shippingMethodInfo.name.length"
				class="mt-1 mb-0">
				<img
					v-if="shippingMethodInfo.image"
					:src="require(`@/assets/images/common/shipping/carriers/${shippingMethodInfo.image}`)"
					class="my-auto border"
					style="width: 45px; height: 30px;">
				<p class="text-muted d-inline">
					<span
						v-if="shippingMethodInfo.name"
						class="ml-1">
						{{ translate(shippingMethodInfo.name) }}
					</span>
					<small
						v-if="deliveryDays.length"
						class="font-italic">
						<br>{{ translate('estimated_date') }}{{ translate(deliveryDays) }}
					</small>
				</p>
				<br>
				<small
					v-if="shippingNotice.length"
					class="font-italic"
					v-html="translate(shippingNotice)" />
			</div>
		</div>
		<div
			v-else
			class="col-12 fade-in text-center d-flex justify-content-center align-items-center">
			<div class="col-12">
				<div class="h2">
					<i class="fa fa-fw fa-spinner fa-pulse" />
					<h4 class="mt-3">
						{{ translate('loading') }}
					</h4>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import {
	SHIPPING_METHOD_CODES,
} from '@/settings/Shipping';
import {
	Countries, Purchase as PurchaseMessages,
} from '@/translations';

export default {
	name: 'ShippingOverview',
	messages: [Countries, PurchaseMessages],
	props: {
		loadingOverride: {
			type: Boolean,
			default: false,
		},
		shippingMethodImport: {
			type: String,
			default: '',
		},
		address: {
			type: Object,
			default: () => ({}),
		},
	},
	computed: {
		shippingMethodInfo() {
			try {
				if (this.shippingMethodImport === '') {
					return {};
				}

				return { name: this.shippingMethodImport, ...SHIPPING_METHOD_CODES[this.shippingMethodImport] };
			} catch (error) {
				return {};
			}
		},
		deliveryDays() {
			try {
				const deliveryDays = this.shippingMethodInfo.delivery_days;
				return deliveryDays[this.country] ? deliveryDays[this.country] : [];
			} catch (error) {
				return [];
			}
		},
		shippingNotice() {
			try {
				const shippingNotice = this.shippingMethodInfo.shipping_notice;
				return shippingNotice[this.country] ? shippingNotice[this.country] : shippingNotice.default;
			} catch (error) {
				return [];
			}
		},
	},
};
</script>
